import "./Project.css"
import React from "react";

interface ProjectProps {
    company: string;
    date: string;
    role: string;
    description: string;
    color: string
}

const Project: React.FC<ProjectProps> = ({company, date, role, description, color}) => {

    return (
        <div className="box">
            <h1>{company}</h1>
            <div className="icon-and-info">
                <div className="icon">
                    <i className="far fa-calendar-alt" style={{height: "20px"}} />
                </div>
                <p>{date}</p>
            </div>
            <div className="icon-and-info">
                <div className="icon">
                    <i className="fas fa-user-tag" />
                </div>
                <p>{role}</p>
            </div>
            <div className="icon-and-info">
                <div className="icon">
                    <i className="fas fa-info-circle" />
                </div>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default Project;
