import "./Resume.css"
import React from "react";

const Resume: React.FC = () => {

    return (
        <div className="resume">
        </div>
    )
}

export default Resume;
