import { intervalToDuration} from "date-fns";
import {useEffect, useState} from "react";

import "./Countdown.css"

const Countdown = () => {
    const [countdown, setCountdown] = useState<Duration>(intervalToDuration({
        start: new Date(),
        end: new Date(2023, 3, 30, 16, 0, 0)
    }))

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(intervalToDuration({
                start: new Date(),
                end: new Date(2023, 3, 30, 16, 0, 0)
            }));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return <div className="countdown">
        {}
        {`${countdown.days && countdown.days > 0 ? `${countdown.days} dager, ` : ""}
        ${countdown.hours && countdown.hours > 0 ? `${countdown.hours} timer, ` : ""}
        ${countdown.minutes && countdown.minutes > 0 ? `${countdown.minutes} minutter, ` : ""}
        ${countdown.seconds && countdown.seconds > 0 ? `${countdown.seconds} sekunder` : ""}`}
    </div>
}

export default Countdown;