import "./Projects.css"
import React from "react";
import Project from "../../components/Project/Project";

const Projects: React.FC = () => {

    return (
        <div className="projects-boxes">
            <Project
                company={"Knowit"}
                date={"Jun 2019 - Present"}
                role={"Group Leader and consultant"}
                description={"Consultant and group leader where I have responsibility for a group of developers."}
                color={"#f6f7f1"}
            />
            <Project
                company={"Entur (via Knowit)"}
                date={"Des 2021 - Present"}
                role={"Web Developer"}
                description={"Team Produkt is responsible for the development and management of all Entur's products across all operators."}
                color={"#f6f7f1"}
            />
            <Project
                company={"Entur (via Knowit)"}
                date={"Jun 2019 - Oct 2021"}
                role={"Fullstack Developer"}
                description={"Team Sales is responsible for the development and management of everything that happens after an offer has been created."}
                color={"#f6f7f1"}
            />
            <Project
                company={"University of Oslo"}
                date={"Des 2017 - Des 2019"}
                role={"Graduate student & scientist"}
                description={"Completed master's thesis and wrote a scientific article on onboarding."}
                color={"#f6f7f1"}
            />
            <Project
                company={"At home"}
                date={"Feb 2022"}
                role={"Side project"}
                description={"Bingo cards for eurovision song contest. See the project here: www.esc.goplen.dev"}
                color={"#f6f7f1"}
            />
            <Project
                company={"At home"}
                date={"Aug 2022"}
                role={"Side project"}
                description={"Created a webapp for showing how to parallell parking with animation. See project here: www.lukeparkering.no"}
                color={"#f6f7f1"}
            />
        </div>
    )
}

export default Projects;
